import ActionName from '@/enums/action-name';
import Role from '@/enums/role';
import RouteName from '@/enums/route-name';
import { FeatureName } from '@/types/feature-names';

const REQUIRED_ROLES: Record<FeatureName, Role[]> = {
    [RouteName.CREATE_SHIPMENT]: [
        Role.GLS_ADMIN,
        Role.PARTNER_CREATOR,
        Role.PARTNER_OPERATOR,
        Role.CUSTOMER_FRONTEND,
        Role.CREATE_SHIPMENT
    ],
    [RouteName.PARCEL_DATA]: [Role.GLS_ADMIN, Role.PARCEL_DATA],
    [RouteName.PARCEL_TRACKING]: [Role.GLS_ADMIN, Role.PARCEL_TRACKING, Role.PARTNER_CUSTOMER_SERVICE],
    [RouteName.REPRINT_LABEL]: [Role.GLS_ADMIN, Role.PARTNER_OPERATOR, Role.REPRINT_LABEL],
    [RouteName.SHIPMENT_OVERVIEW]: [
        Role.GLS_ADMIN,
        Role.CUSTOMER_FRONTEND,
        Role.PARTNER_OPERATOR,
        Role.PARTNER_CREATOR,
        Role.SHIPMENT_OVERVIEW,
        Role.PARTNER_CUSTOMER_SERVICE
    ],
    [RouteName.TRANSPORTATION_LIST_CREATE]: [Role.GLS_ADMIN, Role.CREATE_TRANSPORTATION_LIST],
    [RouteName.TRANSPORTATION_OVERVIEW]: [Role.GLS_ADMIN, Role.TRANSPORTATION_OVERVIEW],
    [ActionName.TRANSPORTATION_STATUS_UPDATE]: [Role.GLS_ADMIN, Role.TRANSPORTATION_OVERVIEW],
    [ActionName.TRANSPORTATION_PARCELS_PREVIEW]: [Role.GLS_ADMIN, Role.TRANSPORTATION_OVERVIEW]
};

export default REQUIRED_ROLES;
